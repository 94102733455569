import React from "react";

import Layout from "../components/layout";
import Header from "../components/header";
import Seo from "../components/seo";

const EventPost = ({ pageContext: { pageInfo } }) => {
  let eventsInfo = pageInfo.event;
  // const eventList = pageInfo.eventList
  let eventDate = new Date(eventsInfo.date).toLocaleDateString("es-ES", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <Layout>
      <Seo title={eventsInfo.title} />
      <Header />
      <main className="event">
        <div className="content">
          <img alt="Imagen de cabecera" src={eventsInfo.image} />
          <div className="event-info">
            <div className="event-title">
              <h1>{eventsInfo.title}</h1>
            </div>{" "}
            <div className="event-description">
              <p>{eventsInfo.description}</p>
            </div>
            <div className="event-content" style={{ width: "90%" }}>
              <div dangerouslySetInnerHTML={{ __html: eventsInfo.content }} />{" "}
            </div>
          </div>{" "}
          <div className="event-meta">
            <div>
              <p>
                <strong>Datos del evento</strong>
              </p>

              <small>Día</small>
              <p>{eventDate}</p>
              <small>Lugar</small>
              <p>{eventsInfo.location}</p>
              <a href={eventsInfo.map_link} className="btn-green">
                ¿Cómo llegar?
              </a>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default EventPost;
